import {axios} from '@/libs/api.request';

export const GetAppList = () =>{
  return axios.request({
    url: '/app/list',
    method: 'get',
  });
}

export const Callback = (data) => {
  return axios.request({
    url: '/UpdateCampaign',
    data: data,
    method: 'post',
  });
}

