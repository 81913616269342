import Vue from 'vue';
import Router from 'vue-router';
import Vuex from 'vuex';

// 业务页面
import Callback from '@/components/Callback'


// 解决路由重复点击问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);
Vue.use(Vuex);

const baseRoutes = [
    {
        path: '/',
        name: 'root',
        redirect: '/callback',
    },
    {
        path: '/callback',
        name: 'callback',
        component: Callback
    },
]

const router = new Router({
    routes: baseRoutes,
    mode: 'history'
});

export default router;