<template>
  <div class="content">
    <Card title="快捷开启关停广告">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100">
        <FormItem label="选择ID类型:" prop="platform">
          <Select v-model="formValidate.platform" placeholder="请选择类型">
            <Option v-for="item in allPlatform" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </FormItem>

        <!--        <FormItem label="罗盘账号:" prop="isCompass">-->
        <!--          <RadioGroup v-model="formValidate.isCompass">-->
        <!--            <Radio label="yes">是</Radio>-->
        <!--            <Radio label="no">否</Radio>-->
        <!--          </RadioGroup>-->
        <!--        </FormItem>-->

        <!--        <FormItem label="选择授权应用:" prop="packageName">-->
        <!--          <Select v-if="formValidate.isCompass==='no'" v-model="formValidate.packageName" filterable placeholder="授权应用">-->
        <!--            <Option v-for="item in allApp" :value="item.app_name" :key="item.app_name">{{ item.app }}</Option>-->
        <!--          </Select>-->

        <!--          <Select v-if="formValidate.isCompass==='yes'" disabled placeholder="未知应用"></Select>-->
        <!--        </FormItem>-->

        <FormItem label="计划/组ID:" prop="accountID">
          <Input class="test" type="textarea" :rows="4" v-model="formValidate.accountID" size="large"
                 placeholder="输入计划/组ID,多个ID用逗号分割"></Input>
        </FormItem>

        <FormItem label="选择操作:" prop="packageName">
          <Select v-model="formValidate.action" placeholder="请选择操作类型">
            <Option v-for="item in allAction" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </FormItem>

        <FormItem v-if="formValidate.action ==='price'" label="价格:" prop="price">
          <Input v-model="formValidate.price" placeholder="请输入出价价格,单位是元"></Input>
        </FormItem>

        <FormItem v-if="formValidate.action ==='status'" label="投放状态:" prop="isUpload">
          <RadioGroup v-model="formValidate.isUpload">
            <Radio label="yes">开启</Radio>
            <Radio label="no">关停</Radio>
          </RadioGroup>

          <!--          <RadioGroup v-if="formValidate.isCompass==='yes'" v-model="formValidate.isUpload">-->
          <!--            <Radio label="yes" disabled>是</Radio>-->
          <!--            <Radio label="no" disabled>否</Radio>-->
          <!--          </RadioGroup>-->
        </FormItem>

        <!--        <FormItem v-if="formValidate.isUpload ==='yes'" label="增长参谋-APPID">-->
        <!--          <Input v-model="formValidate.csjAppID" placeholder="输入穿山甲AppID"/>-->
        <!--        </FormItem>-->

        <FormItem>
          <Row :gutter="16">
            <Col span="16">
              <Button type="primary" @click="handleSubmit('formValidate')">提交</Button>
            </Col>
            <!--            <Col span="6">-->
            <!--              <Button @click="handleReset()" style="margin-left: 8px">Reset</Button>-->
            <!--            </Col>-->
            <!--            <Col span="6" v-if="formValidate.platform === 'kuaishou'">-->
            <!--              <Button type="success" @click="handleJump()">授权链接跳转</Button>-->
            <!--            </Col>-->
          </Row>
        </FormItem>
      </Form>
    </Card>
    <!--    <Modal-->
    <!--        v-model="isModelAuth"-->
    <!--        title="授权链接跳转"-->
    <!--        @on-ok="authOk"-->
    <!--        @on-cancel="authCancel">-->
    <!--      <p>-->
    <!--        请确保磁力引擎登录的快手ID是: <b>{{ formValidate.accountID }}</b>-->
    <!--      </p>-->
    <!--    </Modal>-->
    <Modal
        v-model="isModelSubmit"
        title="确认提交"
        @on-ok="submitOk"
        @on-cancel="submitCancel">
      <List>
        <ListItem>ID类型:<b>{{ platformName[formValidate.platform] }}</b></ListItem>
        <!--        <ListItem>罗盘账号:<b>{{ selectMap[formValidate.isCompass] }}</b></ListItem>-->
        <ListItem>计划/组ID:<b>{{ formValidate.accountID }}</b></ListItem>
        <ListItem v-if="formValidate.action ==='price'">出价:<b>{{ formValidate.price }}</b></ListItem>
        <ListItem v-if="formValidate.action ==='status'">操作状态:<b>{{ selectMap[formValidate.isUpload] }}</b></ListItem>
        <!--        <ListItem v-if="formValidate.csjAppID !== ''">增长参谋-APPID:<b>{{ formValidate.csjAppID }}</b></ListItem>-->
      </List>
    </Modal>
  </div>
</template>

<script>
import {Callback} from "@/api/callback";

export default {
  name: 'Callback',
  data() {
    return {
      appMap: {},
      selectMap: {
        "yes": "开启",
        "no": "关停"
      },
      platformName: {
        "campaign": "计划",
        "group": "广告组",
      },
      isModelSubmit: false,
      isModelAuth: false,
      isSubmit: false,
      platformMap: {
        "kuaishou": 165897661,
        "toutiao": 1722532901119006,
      },

      allPlatform: [
        {
          value: 'campaign',
          label: '计划'
        },
        {
          value: 'group',
          label: '广告组'
        }
      ],
      allAction: [
        {
          value: 'price',
          label: '调整出价'
        },
        {
          value: 'status',
          label: '调整投放状态'
        }
      ],
      allApp: [],
      formValidate: {
        platform: '',
        packageName: 'unknown',
        accountID: '',
        isUpload: 'no',
        isCompass: 'no',
        csjAppID: '',
        price: '',
        action: '',
      },
      ruleValidate: {
        platform: [
          {required: true, message: '授权平台不能为空', trigger: 'change'}
        ],
        packageName: [
          {required: true, message: '授权应用不能为空', trigger: 'change'}
        ],
        accountID: [
          {required: true, message: '账号ID不能为空', trigger: 'blur'}
        ],
        isUpload: [
          {required: true, message: '选择上传增长参谋', trigger: 'change'}
        ],
        isCompass: [
          {required: true, message: '选择罗盘', trigger: 'change'}
        ],
      }
    }
  },
  // created() {
  //   GetAppList().then(res=>{
  //     if(res.data === "获取应用失败"){
  //       this.$Message.error(res.data);
  //       this.allApp.push({"app":"未知应用","app_name":"unknown"})
  //       for (let item of this.allApp) {
  //         this.appMap[item.app_name] = item.app
  //       }
  //       return
  //     }
  //     this.allApp = res.data
  //     this.allApp.push({"app":"未知应用","app_name":"unknown"})
  //     for (let item of this.allApp) {
  //       this.appMap[item.app_name] = item.app
  //     }
  //   })
  // },
  methods: {
    handleSubmit(name) {
      console.log(name)
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.isModelSubmit = true
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    handleReset() {
      this.formValidate.platform = ''
      this.formValidate.packageName = 'unknown'
      this.formValidate.accountID = ''
      this.formValidate.isUpload = 'no'
      this.formValidate.isCompass = 'no'
      this.formValidate.csjAppID = ''
    },
    // handleJump() {
    //   if (this.isSubmit === false) {
    //     this.$Message.error("跳转之前请先提交！！！");
    //     return
    //   }
    //   this.isModelAuth = true
    // },
    // authOk() {
    //   let packageName = this.formValidate.packageName
    //   if (packageName === "unknown") {
    //     packageName = ""
    //   }
    //
    //   let url = `https://developers.e.kuaishou.com/tools/authorize?app_id=` + this.platformMap[this.formValidate.platform]
    //   url += `&scope=%5B%22ad_query%22%2C%22ad_manage%22%2C%22report_service%22%2C%22account_service%22%2C%22public_dmp_service%22%2C%22public_agent_service%22%2C%22public_account_service%22%5D&redirect_uri=`
    //   url += `https://mtasks.dev.tagtic.cn/api/marketing/callback/ks&state={"app_id":` + this.platformMap[this.formValidate.platform] + ","
    //   url += `"package_name":"` + packageName + `","account_id":` + this.formValidate.accountID + "}"
    //   window.location.href = url
    // },
    // authCancel() {
    //   this.isModelAuth = false
    // },
    submitOk() {
      let accountId = this.formValidate.accountID
      //console.log(accountId)
      // if (isNaN(accountId)) {
      //   this.$Message.error("授权账号ID填写错误，授权账号ID是int类型");
      //   return
      // }

      // let csjAppID = 0
      // if (this.formValidate.csjAppID !== '') {
      //   csjAppID = parseInt(this.formValidate.accountID)
      //   if (isNaN(csjAppID)) {
      //     this.$Message.error("增长参谋-APPID填写错误，增长参谋-APPID是int类型");
      //     return
      //   }
      // }
      // if (this.formValidate.isUpload === 'yes' && this.formValidate.packageName === "unknown") {
      //   this.$Message.error("上传增长参谋，授权应用必须选择");
      //   return
      // }

      let isUpload = "2"// 暂停
      if (this.formValidate.isUpload === 'yes') {
        isUpload = "1"  //开启
      }

      // let isCompass = false
      // if (this.formValidate.isCompass === 'yes') {
      //   isCompass = true
      // }

      let packageName = this.formValidate.packageName
      if (packageName === "unknown") {
        packageName = ""
      }
      let price = this.formValidate.price
      let data = {
        // "app_id": this.platformMap[this.formValidate.platform],
        "platform": this.formValidate.platform,
        // "package_name": packageName,
        "account_id": accountId,
        "is_upload": isUpload,
        "price": price,
        "action": this.formValidate.action,
        // "is_compass": isCompass,
        // "csj_app_id": csjAppID,
      }
      // console.log(data)
      Callback(data).then(res => {
        if (res.data.code !== 0) {
          this.$Message.error(res.data.message);
        } else {
          this.isSubmit = true
          this.$Message.info(res.data.message);
        }
      })
    },
    submitCancel() {
      this.isModelSubmit = false
    },
  },
  watch: {
    // 罗盘账号，没有包名，不能上传
    "formValidate.isCompass": function (newData) {
      if (newData === 'no') {
        this.formValidate.packageName = 'unknown'
      } else {
        this.formValidate.isUpload = 'no'
        this.formValidate.packageName = 'unknown'
      }
    },
    "formValidate.isUpload": function (newData) {
      if (newData === 'no') {
        this.formValidate.csjAppID = ''
      }
    },
    "formValidate": {
      handler: function () {
        this.isSubmit = false
      },
      deep: true
    }
  }
}
</script>

<style>
.content {
  text-align: center;
  background-color: #FFFF;
  width: 30%;
  margin: auto;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;
}

b {
  color: #dc2514;
}
</style>
