import Vue from 'vue'
import App from './App'
import iView from 'iview';
import router from './router';
import 'iview/dist/styles/iview.css';

// 关闭向控制台打印开发版本
Vue.config.productionTip = false
Vue.use(iView);

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
